import { HocuspocusProviderConfiguration } from '@hocuspocus/provider';
import classNames from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Profile from '../../../../../../models/profile/Profile';
import { AppState } from '../../../../../../store/store';
import { LIVE_BLOG_PAGE_SIZE } from '../../../constants/PageSize';
import { NEW_POST_ID } from '../../../constants/PostRelated';
import { deleteLiveBlogPost, editLiveBlogPost, getLiveBlogPosts } from '../../../helpers/live-blog-main.helper';
import useHocuspocusWebSocket from '../../../hooks/useHocuspocusWebSocket';
import useRestOnDemand from '../../../hooks/useRestOnDemand';
import { CollaborationAPIWebSocketPayload } from '../../../models/collaboration-api-web-socket.model';
import { LiveBlogPostModel, LiveBlogPostsFilter, LiveBlogPostsResponse, PublishedType } from '../../../models/live-blog-post.model';
import { LiveBlogEditorialAdminContext, OpenModalCallback } from '../../main-components/live-blog-editorial-admin.component';
import { CollaborativeEditorProps } from '../collaborative-editor/collaborative-editor';
import LiveBlogNewPost from '../live-blog-new-post/live-blog-new-post.component';
import LiveBlogPost from '../live-blog-post/live-blog-post.component';
import LiveBlogFeedPagination from './live-blog-feed-pagination.component';

import '../../../style/live-blog-feed.scss';

interface LiveBlogFeedProps {
	onLiveBlogConfigurationUpdate: () => void;
	widgetEditDetails: {
		openModalCallback: OpenModalCallback;
		lastUpdatedWidget: CollaborativeEditorProps['lastUpdatedWidget'];
		setLastUpdatedWidget: React.Dispatch<React.SetStateAction<CollaborativeEditorProps['lastUpdatedWidget']>>;
	};
}

const LiveBlogFeed: FC<LiveBlogFeedProps> = ({ onLiveBlogConfigurationUpdate, widgetEditDetails }) => {
	const [t] = useTranslation();
	const [selectedTab, setSelectedTab] = useState(0);
	const tabDetails = useMemo(() => [{ label: 'published' }, { label: 'highlights' }, { label: 'drafts' }], []);
	const [lockedPosts, setLockedPosts] = useState<{ id: string; adminName: string }[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentlyEditedPost, setCurrentlyEditedPost] = useState<(LiveBlogPostModel & { onEditEnd: () => void }) | null>(null);
	const [listOfPostsFromDB, setListOfPostsFromDB] = useState<LiveBlogPostModel[]>([]);
	const [listOfPosts, setListOfPosts] = useState<(LiveBlogPostModel & { toDelete?: boolean })[]>([]);
	const [meta, setMeta] = useState<LiveBlogPostsResponse['meta']>();
	const [page, setPage] = useState(1);
	const [lastHighlighted, setLastHighlighted] = useState<string | null>(null); // Makes highlighting visually fast, with no waiting for WebSocket response
	const [lastHighlightRemoved, setLastHighlightRemoved] = useState<string | null>(null); // Makes removing highlight visually fast, with no waiting for WebSocket response
	const liveBlogConfiguration = useContext(LiveBlogEditorialAdminContext);
	const selectedTabRef = useRef(selectedTab);
	const profile = useSelector((state: AppState) => state.profile.profile as Profile);

	const { data, refetchOnDemand } = useRestOnDemand<LiveBlogPostsResponse | undefined>(() => {
		const filter: LiveBlogPostsFilter = {
			limit: LIVE_BLOG_PAGE_SIZE,
			page,
			published_type: selectedTab === 2 ? PublishedType.DRAFT : PublishedType.PUBLISHED,
		};

		if (selectedTab === 1) {
			filter.is_highlight = true;
		}

		return liveBlogConfiguration && getLiveBlogPosts(liveBlogConfiguration.id!, filter, t('live_blog_posts_get_error'));
	}, !liveBlogConfiguration);

	const processWebSocketMessages: HocuspocusProviderConfiguration['onStateless'] = useCallback(({ payload }) => {
		let data: CollaborationAPIWebSocketPayload | undefined = undefined;

		try {
			data = JSON.parse(payload);
		} catch (e) {
			toast.error(t('parsing_error'));
			console.error(e);
		}

		if (!data) {
			return;
		}

		switch (data.action) {
			case 'CREATE':
			case 'DELETE':
				if (
					data.meta &&
					((!data.meta.is_highlight && selectedTabRef.current === 1) ||
						(data.meta.published_type === PublishedType.PUBLISHED && selectedTabRef.current === 2) ||
						(data.meta.published_type === PublishedType.DRAFT && selectedTabRef.current !== 2))
				) {
					return;
				}

				refetchOnDemand();
				break;
			case 'UPDATE':
				if (data.entity_type === 'DOCUMENT') {
					onLiveBlogConfigurationUpdate();
					return;
				}

				refetchOnDemand();
				break;
			case 'LOCK':
				const admin = typeof data.admin === 'string' ? JSON.parse(data.admin) : data.admin;

				setLockedPosts((prev) => {
					const updatedLockedPosts = [...prev];

					updatedLockedPosts.push({ id: data!.entity_id, adminName: admin.name });
					return updatedLockedPosts;
				});
				break;
			case 'UNLOCK':
				if (data.reason === 'TIMEOUT') {
					setIsModalOpen(false);
				}

				setLockedPosts((prev) => {
					const updatedLockedPosts = [...prev];
					const indexOfUnlockedPost = updatedLockedPosts.findIndex(({ id }) => id === data!.entity_id);

					updatedLockedPosts.splice(indexOfUnlockedPost, 1);
					return updatedLockedPosts;
				});
				break;
		}
	}, []);

	const { provider: hocuspocusProvider, socket } = useHocuspocusWebSocket(
		liveBlogConfiguration && liveBlogConfiguration.id!,
		processWebSocketMessages,
	);

	const toggleLock = (id: string, action: 'LOCK' | 'UNLOCK') => {
		hocuspocusProvider &&
			hocuspocusProvider.sendStateless(
				JSON.stringify({
					action,
					entity_type: 'SUBDOCUMENT',
					entity_id: id,
					document_id: liveBlogConfiguration!.id,
					admin: {
						id: profile.id,
						name: profile.name,
					},
				}),
			);
	};

	useEffect(() => {
		if (data) {
			setListOfPostsFromDB(data.data);
			setMeta(data.meta);
		}
	}, [data]);

	useEffect(() => {
		setListOfPosts((prev) => {
			if (prev.length === 0) {
				return listOfPostsFromDB || [];
			}

			const updatedListOfPosts = prev.map((post) => {
				if (listOfPostsFromDB && listOfPostsFromDB.find(({ id }) => post.id === id)) {
					return post;
				}

				// Marks any post that is to be removed for safe deletion
				return { ...post, toDelete: true };
			});

			return updatedListOfPosts;
		});
	}, [listOfPostsFromDB]);

	useEffect(() => {
		selectedTabRef.current = selectedTab;
		refetchOnDemand();
	}, [selectedTab]);

	useEffect(() => {
		refetchOnDemand();
	}, [page]);

	useEffect(() => {
		if (currentlyEditedPost) {
			toggleLock(currentlyEditedPost.id, isModalOpen ? 'LOCK' : 'UNLOCK');
		}

		if (!isModalOpen) {
			setCurrentlyEditedPost(null);

			if (widgetEditDetails.lastUpdatedWidget && widgetEditDetails.lastUpdatedWidget.widgetDetails.subDocumentId !== NEW_POST_ID) {
				// Clears "lastUpdatedWidget" cache
				widgetEditDetails.setLastUpdatedWidget(undefined);
			}
		}
	}, [currentlyEditedPost, isModalOpen]);

	const onEditEnd = () => setIsModalOpen(false);

	const scheduledDeleteOperationTimeout = useRef<number>();

	const scheduleDelete = () => {
		// NB: The whole functionality related to "scheduleDelete" and "toDelete" is a workaround of the following Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764
		clearTimeout(scheduledDeleteOperationTimeout.current);

		scheduledDeleteOperationTimeout.current = window.setTimeout(() => {
			setLastHighlighted(null);
			setLastHighlightRemoved(null);
			setListOfPosts(listOfPostsFromDB || []);
		}, 50);
	};

	useEffect(() => {
		scheduleDelete();
	}, [listOfPosts]);

	useEffect(() => {
		const beforeunloadHandler = () => {
			if (currentlyEditedPost) {
				toggleLock(currentlyEditedPost.id, 'UNLOCK');
			}
		};

		window.addEventListener('beforeunload', beforeunloadHandler);

		return () => window.removeEventListener('beforeunload', beforeunloadHandler);
	}, [currentlyEditedPost]);

	useEffect(() => {
		return () => {
			if (hocuspocusProvider) {
				hocuspocusProvider.disconnect();
				socket.disconnect();
			}
		};
	}, [hocuspocusProvider, socket]);

	useEffect(() => {
		return () => clearTimeout(scheduledDeleteOperationTimeout.current);
	}, []);

	return (
		<>
			<div className='live-blog-feed'>
				<div className='live-blog-feed-tabs'>
					{tabDetails.map((details, i) => (
						<div
							key={details.label}
							className={classNames('live-blog-feed-tab', { selected: selectedTab === i })}
							onClick={() => setSelectedTab(i)}
						>
							{t(details.label)}
						</div>
					))}
				</div>
				<div className='live-blog-feed-content'>
					{listOfPosts.map((post) => {
						const { id, body, minute, author, is_highlight, display_timestamp, sponsors, sport_event_type, toDelete } = post;
						const lockInformation = lockedPosts.find((lockedPost) => lockedPost.id === id);
						let isHighlighted = is_highlight;

						if (lastHighlighted !== null && lastHighlighted === id) {
							isHighlighted = true;
						} else if (lastHighlightRemoved !== null && lastHighlightRemoved === id) {
							isHighlighted = false;
						}

						return (
							<LiveBlogPost
								key={id}
								id={id}
								body={body}
								minute={minute}
								author={author}
								is_highlight={isHighlighted}
								display_timestamp={display_timestamp}
								locked={!!lockInformation}
								editedBy={lockInformation ? lockInformation.adminName : undefined}
								sponsors={sponsors}
								sport_event_type={sport_event_type}
								onDelete={() =>
									deleteLiveBlogPost(liveBlogConfiguration!.id!, id, t('live_blog_post_delete_success'), t('live_blog_post_delete_error')).catch(
										(e) => undefined,
									)
								}
								onEdit={() => {
									setCurrentlyEditedPost({ ...post, onEditEnd });
									setIsModalOpen(true);
								}}
								onHighlight={async (highlight) => {
									const post = listOfPosts.find((post) => post.id === id);

									if (post) {
										const updatedPost = { ...post, is_highlight: highlight };

										try {
											highlight ? setLastHighlighted(id) : setLastHighlightRemoved(id);
											await editLiveBlogPost(liveBlogConfiguration!.id!, id, updatedPost, '', t('live_blog_post_highlight_error'));
										} catch (e) {}
									}
								}}
								toDelete={toDelete}
							/>
						);
					})}
				</div>
				<LiveBlogFeedPagination currentPage={page} setPage={setPage} totalPages={meta ? Math.max(meta.total_pages, 1) : 1} />
			</div>

			<Modal isOpen={isModalOpen} size='lg'>
				<ModalHeader toggle={() => setIsModalOpen(false)}>{t('editing_post')}</ModalHeader>
				<ModalBody>
					<LiveBlogNewPost
						existingPost={currentlyEditedPost}
						openModalCallback={widgetEditDetails.openModalCallback}
						lastUpdatedWidget={widgetEditDetails.lastUpdatedWidget}
					/>
				</ModalBody>
			</Modal>
		</>
	);
};

export default LiveBlogFeed;
